module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-remark-images@7.14.0_gatsby-plugin-sharp@5.14.0_gatsby@5.14.0_babel-eslint@10.1.0_esli_uvkdypqqfi5oobt43j5cadeqqm/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"withWebp":true,"quality":95,"maxWidth":700,"showCaptions":true,"linkImagesToOriginal":true,"markdownCaptions":false,"backgroundColor":"white","withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.14.0_gatsby@5.14.0_babel-eslint@10.1.0_eslint@9.17.0__react-dom@18.3_obc233lp2bg2cxu52et5qqj2z4/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Yan's Blog","short_name":"mockee","start_url":"/","background_color":"#fff","theme_color":"hsla(198, 100%, 46%, 0.97)","display":"minimal-ui","icon":"src/images/avatar.jpg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"65ec93bb9925c1a24d8d5c642ef76ce2"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-typography@5.14.0_gatsby@5.14.0_babel-eslint@10.1.0_eslint@9.17.0__react-dom@18_54hddj6oiqdsxfqfnztuoc7sfq/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.14.0_babel-eslint@10.1.0_eslint@9.17.0__react-dom@18.3.1_react@18.3.1__react@18.3.1__byrljamhbc7m4hp4wbmrzo55ki/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
